import { lazy } from "react";
import ViewApplicentProfile from "../components/applicant/newProfile/viewApplicentProfile/viewApplicentProfile";
// import AddProfile from '../components/applicant/addProfile/addProfile';
import ApplicantTest from "../components/applicant/applicantTest/applicantTest";
import SpokenTest from "../components/applicant/spokenTest/spokenTest";
import WrittenTest from "../components/applicant/writtenTest/writtenTest";
import F2F from "../components/applicant/f2f/f2f";
import AdminDashboard from "../components/admin/dashboard/dashboard";
import CreatePositions from "../components/admin/createPositions/createPositions";
import UpdatePositions from "../components/admin/updatePositions/updatePositions";
import CreateEmployee from "../components/admin/createEmployee/createEmployee";
import EmployeeList from "../components/admin/employeeList/employeeList";
import Dashboard from "../components/hrHead/dashboard/dashboard";
import AssignToRecruiter from "../components/hrHead/assignToRecruiter/assignToRecruiter";
import OcefDashboard from "../components/ocef/dashboard/dashboard";
import RecruiterDashboard from "../components/recruiter/dashboard/dashboard";
import EADashboard from "../components/recruiter/dashboard/eaDashboard";
import InvigilatorDashboard from "../components/recruiter/dashboard/invigilatorDashboard";
import SpokenTestCompleted from "../components/recruiter/spokenTestCompleted/spokenTestCompleted";
import SpokenScoreUpload from "../components/recruiter/spokenScoreUpload/spokenScoreUpload";
import WrittenTestCompleted from "../components/recruiter/writtenTestCompleted/writtenTestCompleted";
import WrittenScoreUpload from "../components/recruiter/writtenScoreUpload/writtenScoreUpload";
import ApplicantProfile from "../components/recruiter/applicantProfile/applicantProfileNew";
import McqAssignTest from "../components/recruiter/mcqTest/assignTest/assignTest";
import McqReAssignTest from "../components/recruiter/mcqTest/reAssignTest/reAssignTest";
import McqCompleted from "../components/recruiter/mcqTest/mcqCompleted/mcqCompleted";
import WrittenAssignTest from "../components/recruiter/writtenTest/assignTest/assignTest";
import WrittenReAssignTest from "../components/recruiter/writtenTest/reAssignTest/reAssignTest";
import PlanerRound from "../components/recruiter/planerRound/planerRound";
import Round3 from "../components/recruiter/round3/round3";
import Round2 from "../components/recruiter/round2/round2";
import Round1 from "../components/recruiter/round1/round1";
import AssignOtherRecruiter from "../components/recruiter/assignOtherRecruiter/assignOtherRecruiterNew";
import InterviewDetailsRecruiter from "../components/recruiter/interviewDetail/interviewDetail";
import InterviewPanel from "../components/admin/interviewPanel/interviewPanel";
import CreateTopic from "../components/admin/createTopic/createTopic";
import SpokenTopic from "../components/admin/spokenTopic/spokenTopic";
import QuestionPaper from "../components/admin/questionPaper/questionPaper";
import AddQuestion from "../components/admin/addQuestion/addQuestion";
import ViewQuestion from "../components/admin/viewQuestion/viewQuestion";
import OcfpMcq from "../components/recruiter/ocfpMcq/ocfpMcq";
import AssignSpokenTest from "../components/recruiter/assignSpokenTest/assignSpokenTest";
import ViewChapters from "../components/admin/viewChapters/viewChapter";
import ViewChapterNew from "../components/admin/viewChapters/viewChapterNew";
import viewSubjects from "../components/admin/viewSubjects/viewSubjects";
import ViewSubjectsNew from "../components/admin/viewSubjects/viewSubjectsNew";
import Instructions from "../components/admin/instructions/instructions";

import ViewF2Fquestions from "../components/admin/FaceToFace/viewF2Fquestions";
import ViewParameter from "../components/admin/parameter/viewParameter";
import ChangePassword from "../components/admin/changePassword/changePassword";
import InterviewerDashboard from "../components/interviewer/interviewer";
import Calendar from "../components/interviewer/calendar/calendar";
import TodaysInterview from "../components/interviewer/todaysInterview/todaysInterview";
import UpCommingInterview from "../components/interviewer/upCommingInterview/upCommingInterview";
import PendingInterview from "../components/interviewer/pendingInterview/pendingInterview";
import InterviewCompleted from "../components/interviewer/interviewCompleted/interviewCompleted";
// import TodaysSpoken from '../components/interviewer/todaysSpokenTest/todaysSpoken';
import SpokenCompleted from "../components/interviewer/spokenCompleted/spokenCompleted";
import FaceToFace from "../components/recruiter/FaceToFace/faceToFace";
import OfferLetter from "../components/recruiter/offerLetter/offerLetter";
import OfferLetters from "../components/recruiter/offerLetterOutBound/offerLetterNew";
import OfferListViews from "../components/recruiter/offerLetterOutBound/offerViewNew";
import ScoreAssignedBy from "../components/hrHead/scoreAssignedBy/scoreAssignedBy";
import SuperDashboard from "../components/superAdmin/dashboard/dashboard";
import UserTable from "../components/superAdmin/userTable/userTable";
import Round4 from "../components/recruiter/round4/round4";
import BussinesHeadRound from "../components/recruiter/bussinesHeadRound/bussinesHeadRound";
import HrRound from "../components/recruiter/hrRound/hrRound";
import ResumeRepository from "../components/common/resumeRepository/resumeRepositoryNew";
import DemoTest from "../components/ocef/demoTest/demoTest";
import TransferTabs from "../components/principal/TransferTabs";

import ReviewerDashBoard from "../components/reviewer/dashboard/ReviewerDashboard";
import ApplicantReview from "../components/reviewer/applicantReview/applicantReview";
import OcfpAssign from "../components/hrHead/ocfpAssign/ocfpAssign";
import OcfpReAssign from "../components/hrHead/ocfpReassign/ocfpReassign";
import OcfcInterview from "../components/interviewer/ocfcInterview/OcfcInterview";
import OcfcHrInterview from "../components/hrHead/ocfcHrInterview/OcfcInterview";
import process from "../components/applicant/process/process";
import FaceToFaceAllRounds from "../components/recruiter/faceToFaceAllRounds/faceToFaceAllRoundsNew";
import AddRole from "../components/admin/addRole/addRole";
import AssignScore from "../components/recruiter/assignScore/assignScoreNew";
import ViewProfile from "../components/viewProfile/viewProfile";
import CampusDrive from "../components/hrHead/campusDrive/CampusDrive";
import Parameters from "../components/interviewer/todaysInterview/Parameters";
import ApplicentHistory from "../components/admin/applicentHistory/applicentHistory";
import InterviewDetails from "../components/admin/interviewDetails/interviewDetails";
import FaceToFaceReassign from "../components/recruiter/faceToFaceReassign/facetoFaceReassignNew";
import SubjectHeadDashboard from "../components/subjectHead/subjectHeadDashboard";
import PrincipalDashboard from "../components/principal/principalDashboard";
import ApplicantDashboard from "../components/applicant/applicantDashboard";
import ApplicantOfferLetter from "../components/applicant/applicantOfferLetter";
import SearchApplicantProfile from "../components/recruiter/searchApplicantProfile/applicantProfileNew";
import OtherApplicants from "../components/admin/otherapplicants/otherApplicants";
import Reports from "../components/admin/reports/reportsNew";
import PositionReport from "../components/admin/positionReport/positionReportNew";
import OfferReport from "../components/admin/offerReport/offerReportNew";
import CrudBranch from "../components/admin/crudBranch/crudBranch";
import CrudDesignation from "../components/admin/crudDesignation/crudDesignation";
import RoleDetails from "../components/admin/roleDetails/roleDetails";
import Branch from "../components/admin/branches/branches";
import City from "../components/admin/cities/cities";
import State from "../components/admin/state/state";
import StateCity from "../components/admin/stateCity/stateCity";
import grade from "../components/admin/grade/grade";
import PreviousCandidate from "../components/recruiter/previousCandidate/previousCandidate";
import MrfList from "../components/admin/mrf/mrfList/mrfListNew";
import MrfForm from "../components/admin/mrf/mrfForm/mrfFormNew";
import CrudBranchForm from "../components/admin/CrudBranchForm/crudBranchForm";
import crudBranchForm from "../components/admin/CrudBranchForm/crudBranchForm";
import LinkedinScraper from "../components/admin/linkedinScraper/linkedinScraper";
import BulkCandidate from "../components/admin/bulkCandidate";
import MrfFormApplicants from "../components/admin/mrf/mrfList/mrfFormApplicants";
import JobPosts from "../components/common/jobPosts";
import CommonDashboard from "../components/dashboard/dashboard";
import MrfDashboard from "../components/admin/mrf/mrfDashboard/mrfDashboard";
import TeamPerformance from "../components/admin/teamPerformance/teamPerformance";
import OverViewDashboard from "../components/overview/overview";
import MRFApproverDashboard from "../components/MRFApproverDashboard";
import RewardParameters from "../components/rewards/parameters";
import DepartmentWeightage from "../components/rewards/departmentWeightage";
import EmployeeScoring from "../components/rewards/employeeScoring";
import TeacherReferralRepo from "../components/teacherReferralRepo/teacherReferralRepo";
import PositionType from "../components/admin/positionType/positionType";
import AcademicYearsList from "../components/admin/academicYearsList/academicYearsList";
import OfferLetterApproval from "../components/recruiter/offerLetterOutBound/offerLetterApproval";
import ViewApplicantProfile from "../components/admin/mrf/mrfList/viewApplicantProfile";
import OfferBudget from "../components/MRFApproverDashboard/OfferBudget";
import OfferLetterList from "../components/MRFApproverDashboard/OfferLetterList";
import AllInterviews from "../components/interviewer/allInterviews";
import EmployeeListNew from "../components/admin/employeeList/employeeListNew";
import BudgetApprovalList from "../components/MRFApproverDashboard/BudgetApprovalList";

const routeList = [
  {
    path: "/",
    component: CommonDashboard,
    roles: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
    ],
    exact: true,
  },
  {
    name: "OverView",
    path: "/applicants-overview",
    component: OverViewDashboard,
    roles: [1, 3, 4],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/admin-dashboard",
    component: AdminDashboard,
    roles: [1],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/recruiter-dashboard",
    component: RecruiterDashboard,
    roles: [3],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/hrhead-dashboard",
    component: Dashboard,
    roles: [4],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/superadmin-dashboard",
    component: SuperDashboard,
    roles: [10],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/principal-dashboard",
    component: PrincipalDashboard,
    roles: [8],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/invigilator-dashboard",
    component: InvigilatorDashboard,
    roles: [20],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/ea-dashboard",
    component: EADashboard,
    roles: [19],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/reviewer-dashboard",
    component: ReviewerDashBoard,
    roles: [11],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/interviewer-dashboard",
    component: InterviewerDashboard,
    roles: [7],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/subjecthead-dashboard",
    component: SubjectHeadDashboard,
    roles: [6],
    exact: true,
  },
  {
    name: "Dashboard",
    path: "/mrf-approver-dashboard",
    component: MRFApproverDashboard,
    roles: [13, 21],
    exact: true,
  },
  {
    name: "Team Performance",
    path: "/team-performance",
    component: TeamPerformance,
    roles: [1],
    exact: true,
  },
  {
    name: "Calendar",
    path: "/calendar",
    component: Calendar,
    roles: [7],
    exact: true,
  },
  {
    name: "MRF List",
    path: "/mrf_list",
    component: MrfList,
    roles: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
    ],
    exact: true,
  },
  {
    name: "MRF Form Applicants",
    path: "/mrf_form_applicants",
    component: MrfFormApplicants,
    roles: [1, 3, 4],
    exact: true,
  },
  // {
  //   name: "MRF Form",
  //   path: "/mrf_form",
  //   component: MrfForm,
  //   roles: [
  //     1,
  //     2,
  //     3,
  //     4,
  //     5,
  //     6,
  //     7,
  //     8,
  //     9,
  //     10,
  //     11,
  //     12,
  //     13,
  //     14,
  //     15,
  //     16,
  //     17,
  //     18,
  //     19,
  //     20,
  //     21,
  //   ],
  //   exact: true,
  // },
  {
    name: "MRF Dashboard",
    path: "/mrf_dashboard",
    component: MrfDashboard,
    roles: [1, 3, 4, 21, 13],
    exact: true,
  },
  {
    name: "Bulk Upload Candidate",
    path: "/bulk_candidate",
    component: BulkCandidate,
    roles: [1, 3, 4],
    exact: true,
  },
  {
    name: "Job Posts",
    path: "/other-job-posts",
    component: JobPosts,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "LinkedIn Scraper",
    path: "/linkedin_scraper",
    component: LinkedinScraper,
    roles: [1],
    exact: true,
  },
  {
    name: "Create Position",
    path: "/create_positions",
    component: CreatePositions,
    roles: [1, 3, 4],
    exact: true,
  },
  // {
  //   name: "Create Role",
  //   path: "/add_role",
  //   component: AddRole,
  //   roles: [1],
  //   exact: true,
  // },
  {
    name: "Position Types",
    path: "/position_types",
    component: PositionType,
    roles: [1],
    exact: true,
  },
  {
    name: "Academic Year",
    path: "/academic_year",
    component: AcademicYearsList,
    roles: [1],
    exact: true,
  },
  {
    name: "Create Employee",
    path: "/create_Employee",
    component: CreateEmployee,
    roles: [1],
    exact: true,
  },
  {
    name: "Position List",
    path: "/update_positions",
    component: UpdatePositions,
    roles: [1, 3, 4],
    exact: true,
  },
  {
    name: "Employee List",
    path: "/employee_List",
    component: EmployeeListNew,
    roles: [1],
    exact: true,
  },
  {
    name: "Written Topics",
    path: "/create_topic",
    component: CreateTopic,
    roles: [1],
    exact: true,
  },
  {
    name: "Spoken Topics",
    path: "/spoken_topic",
    component: SpokenTopic,
    roles: [1],
    exact: true,
  },
  // {
  //   name: "MCQ Test Papers",
  //   path: "/question_paper",
  //   component: QuestionPaper,
  //   roles: [1],
  //   exact: true,
  // },
  // {
  //   name: "Interview Panel",
  //   path: "/interview_panel",
  //   component: InterviewPanel,
  //   roles: [1],
  //   exact: true,
  // },
  {
    name: "City",
    path: "/city",
    component: City,
    roles: [1],
    exact: true,
  },
  {
    name: "Branch",
    path: "/branch",
    component: Branch,
    roles: [1],
    exact: true,
  },
  {
    name: "State",
    path: "/state",
    component: State,
    roles: [1],
    exact: true,
  },
  {
    name: "State City Details",
    path: "/state_city_details",
    component: StateCity,
    roles: [1],
    exact: true,
  },
  {
    name: "Branch details",
    path: "/branch_details",
    component: CrudBranch,
    roles: [1],
    exact: true,
  },
  {
    name: "Create Branch",
    path: "/CrudBranchForm",
    component: CrudBranchForm,
    roles: [1],
    exact: true,
  },
  {
    name: "Designation details",
    path: "/designation_details",
    component: CrudDesignation,
    roles: [1],
    exact: true,
  },
  // {
  //   name: "Role details",
  //   path: "/role_details",
  //   component: RoleDetails,
  //   roles: [1],
  //   exact: true,
  // },
  {
    name: "Grade/Level",
    path: "/grade",
    component: grade,
    roles: [1],
    exact: true,
  },
  {
    name: "Subjects/Topics",
    path: "/viewSubjects",
    // component: viewSubjects,
    component: ViewSubjectsNew,
    roles: [1],
    exact: true,
  },
  {
    name: "Chapters",
    path: "/View_Chapters",
    // component: ViewChapters,
    component: ViewChapterNew,
    roles: [1],
    exact: true,
  },
  {
    name: "Create Question",
    path: "/add_Question",
    component: AddQuestion,
    roles: [1, 6],
    exact: true,
  },
  {
    name: "Questions",
    path: "/View_Questions",
    component: ViewQuestion,
    roles: [1, 6],
    exact: true,
  },
  // {
  //   name: "F2F Questions",
  //   path: "/view_F2F_questions",
  //   component: ViewF2Fquestions,
  //   roles: [1],
  //   exact: true,
  // },
  // {
  //   name: "F2F Parameters",
  //   path: "/View_F2F_parameter",
  //   component: ViewParameter,
  //   roles: [1],
  //   exact: true,
  // },
  {
    name: "Instructions",
    path: "/View_Instructions",
    component: Instructions,
    roles: [1],
    exact: true,
  },
  {
    name: "Reset Password",
    path: "/Reset_Password",
    component: ChangePassword,
    roles: [1],
    exact: true,
  },
  {
    name: "Interview Details",
    path: "/interview_details",
    component: InterviewDetails,
    roles: [1, 3],
    exact: true,
  },
  // {
  //   name: "Applicant History",
  //   path: "/applicant_history",
  //   component: ApplicentHistory,
  //   roles: [1, 3, 4, 6, 7, 8, 11, 19, 20],
  //   exact: true,
  // },
  {
    name: "Applicant Review",
    path: "/applicant_review",
    component: ApplicantReview,
    roles: [11],
    exact: true,
  },
  // {
  //   name: "Other Applicants",
  //   path: "/other_applicants",
  //   component: OtherApplicants,
  //   roles: [1, 3, 4, 19],
  //   exact: true,
  // },
  {
    name: "Resume Repository",
    path: "/resume_repository",
    component: ResumeRepository,
    roles: [1, 3, 4, 6, 7, 8, 11],
    exact: true,
  },
  {
    name: "Reports",
    path: "/report",
    component: Reports,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "Position Report",
    path: "/position_report",
    component: PositionReport,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "Offer Report",
    path: "/offer_report",
    component: OfferReport,
    roles: [1, 3],
    exact: true,
  },
  {
    path: "/view_profile",
    component: ViewProfile,
    roles: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
    ],
    exact: true,
  },
  {
    name: "Search Applicant Profile",
    path: "/search_applicant_profile",
    component: SearchApplicantProfile,
    roles: [3, 19],
    exact: true,
  },
  // {
  //   name: "Applicant Profile",
  //   path: "/applicant_profile",
  //   component: ApplicantProfile,
  //   roles: [3],
  //   exact: true,
  // },
  {
    name: "Score Upload",
    path: "/Score_upload",
    component: AssignScore,
    roles: [1, 3, 19, 20],
    exact: true,
  },
  // {
  //   name: "OcfpMcq Applicant",
  //   path: "/Ocfp_MCQ",
  //   component: OcfpMcq,
  //   roles: [3],
  //   exact: true,
  // },
  {
    name: "Face To Face",
    path: "/Face_To_Face_All",
    component: FaceToFaceAllRounds,
    roles: [1, 3, 19],
    exact: true,
  },
  {
    name: "Re Schedule Face To Face",
    path: "/Face_To_Face_Reassign",
    component: FaceToFaceReassign,
    roles: [3, 19],
    exact: true,
  },
  {
    name: "Offer Letter",
    path: "/offer_letters",
    component: OfferLetters,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "Released Offer Letter List",
    path: "/offer_letters_List",
    component: OfferListViews,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "Assign To Recruiter",
    path: "/assignToRecruiter",
    component: AssignToRecruiter,
    roles: [4],
    exact: true,
  },
  // {
  //   name: "ReAssign Recruiter",
  //   path: "/assignToOtherRecruiter",
  //   component: AssignOtherRecruiter,
  //   roles: [3, 4, 19],
  //   exact: true,
  // },
  // {
  //   name: "Previous Candidate",
  //   path: "/previous_candidate",
  //   component: PreviousCandidate,
  //   roles: [3],
  //   exact: true,
  // },
  {
    name: "Todays Interviews",
    path: "/todays_interview",
    component: TodaysInterview,
    roles: [3, 4, 7, 8, 19],
    exact: true,
  },

  {
    name: "All Interviews",
    path: "/all-interviews",
    component: AllInterviews,
    roles: [3, 4, 7, 8, 19],
    exact: true,
  },
  // {
  //   name: "Pending Interviews",
  //   path: "/pending_interview",
  //   component: PendingInterview,
  //   roles: [3, 4, 7, 8],
  //   exact: true,
  // },
  // {
  //   name: "Completed Interviews",
  //   path: "/interviews_completed",
  //   component: InterviewCompleted,
  //   roles: [3, 4, 7, 8],
  //   exact: true,
  // },

  {
    name: "Ocfp Assign",
    path: "/Ocfp_Assign",
    component: OcfpAssign,
    roles: [3, 4],
    exact: true,
  },
  {
    name: "Ocfp ReAssign",
    path: "/Ocfp_ReAssign",
    component: OcfpReAssign,
    roles: [3, 4],
    exact: true,
  },
  {
    name: "OCFC Interview",
    path: "/ocfcHrInterview",
    component: OcfcHrInterview,
    roles: [3, 4, 7],
    exact: true,
  },
  {
    name: "Campus Drive",
    path: "/campusDrive",
    component: CampusDrive,
    roles: [3, 4],
    exact: true,
  },

  {
    name: "User Table",
    path: "/user_Table",
    component: UserTable,
    roles: [10],
    exact: true,
  },

  // {
  //   name: "Transfer Requests",
  //   path: "/transfer_requests",
  //   component: TransferTabs,
  //   roles: [8],
  //   exact: true,
  // },
  {
    name: "Employee Scoring",
    path: "/employee_rewards",
    component: EmployeeScoring,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "Teacher Referral Repository",
    path: "/teacher_referral_repository",
    component: TeacherReferralRepo,
    roles: [1, 3],
    exact: true,
  },
  {
    name: "Offer Letter Approval",
    path: "/offer-letter-approval",
    component: OfferLetterApproval,
    roles: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
    ],
    exact: true,
  },

  {
    name: "View Applicant Profile",
    path: "/view-applicant-profile",
    component: ViewApplicantProfile,
    roles: [1, 3, 4],
    exact: true,
  },
  {
    name: "Offer Letter List",
    path: "/offer_letter_list_mrf",
    component: OfferLetterList,
    roles: [1, 4, 13, 21],
    exact: true,
  },
  {
    name: "Offer Budget Approval",
    path: "/offer_budget_approval",
    component: OfferBudget,
    roles: [13, 21],
    exact: true,
  },
  {
    name: "Budget Approval List",
    path: "/budget_approval_list",
    component: BudgetApprovalList,
    roles: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
    ],
    exact: true,
  },
];

export default routeList;
