import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import styles from "./mrfList.style";
import PropTypes from "prop-types";
import {
  AddCircleOutlineOutlined,
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import Loader from "../../../../hoc/loader";
import { useAlert } from "../../../../hoc/alert";
import urls from "../../../../url";
import axios from "axios";

const MrfList = ({ classes }) => {
  const alert = useAlert();
  const history = useHistory();
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [dropDownsData] = useState({
    positionTypeList: [
      { id: "Academic", value: "Academic" },
      { id: "Non Academic", value: "Non Academic" },
      { id: "IT Recruitment", value: "IT Recruitment" },
      {
        id: "Marketing and Communications",
        value: "Marketing and Communications",
      },
      {
        id: "Sales and Business Development",
        value: "Sales and Business Development",
      },
    ],
    mrfStatusList: [
      { id: "pending", value: "Open" },
      { id: "on_hold", value: "On Hold" },
      { id: "closed", value: "Closed" },
    ],
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [positionType, setPositionType] = useState();
  const [positionTypeList] = useState(dropDownsData?.positionTypeList);
  const [mrfStatus, setMrfStatus] = useState();
  const [mrfStatusList] = useState(dropDownsData?.mrfStatusList);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [mrfList, setMrfList] = useState([]);
  const [flag, setFlag] = useState(true);
  useEffect(() => {
    fetchMrfList();
  }, [currentPage, flag]);
  const handleClear = () => {
    setStartDate();
    setEndDate();
    setPositionType();
    setMrfStatus();
    setCurrentPage(0);
    setFlag(!flag);
  };
  const fetchMrfList = () => {
    const formDataParams = {
      start_date: startDate ? startDate + "T00:00:00" : null,
      end_date: endDate ? endDate + "T23:59:59" : null,
      position_type: positionType,
      status: mrfStatus,
      page: currentPage + 1,
    };
    setLoading(true);
    axios
      .get(`${urls.mrfForm}`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          setMrfList(response?.data?.results);
          setTotalPages(response?.data?.total_pages);
          alert.success("Data Fetched Successfully");
          setLoading(false);
        }
      })
      .catch((error) => {
        alert.error("Something Went Wrong. Please Try Again");
        setLoading(false);
      });
  };
  const handleExportReport = () => {
    if (!startDate || !endDate) {
      alert.warning("Select Start Date & End Date to Export");
    }
    // report download api logic here
  };
  const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow>
          <TableCell style={{ border: "1px solid black" }} align="center">
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell style={{ border: "1px solid black" }} align="left">
            {row?.employee}
          </TableCell>
          <TableCell style={{ border: "1px solid black" }} align="left">
            {row?.position_type}
          </TableCell>
          <TableCell style={{ border: "1px solid black" }} align="left">
            {row?.department}
          </TableCell>
          <TableCell style={{ border: "1px solid black" }} align="left">
            {row?.experience}
          </TableCell>
          <TableCell style={{ border: "1px solid black" }} align="center">
            <Grid container>
              <Grid
                item
                xs={4}
                style={{
                  border: "1px solid #000",
                  backgroundColor:
                    row?.status === "pending" ? "blue" : "transparent",
                  color: row?.status === "pending" ? "white" : "black",
                  fontWeight: row?.status === "pending" ? "bold" : "",
                }}
              >
                Open
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  border: "1px solid #000",
                  backgroundColor:
                    row?.status === "on_hold" ? "grey" : "transparent",
                  color: row?.status === "on_hold" ? "white" : "black",
                  fontWeight: row?.status === "on_hold" ? "bold" : "",
                }}
              >
                On Hold
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  border: "1px solid #000",
                  backgroundColor:
                    row?.status === "closed" ? "green" : "transparent",
                  color: row?.status === "closed" ? "white" : "black",
                  fontWeight: row?.status === "closed" ? "bold" : "",
                }}
              >
                Closed
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <input type="number" style={{ width: "50px", height: "30px" }} />
              <p style={{ color: "darkblue" }}> &nbsp; / 100</p>
              <Button
                color="primary"
                variant="outlined"
                style={{ marginLeft: "5px", width: "60px", height: "20px" }}
              >
                Update
              </Button>
            </Grid>
          </TableCell>
          <TableCell style={{ border: "1px solid black" }} align="center">
            <Grid container justifyContent="center" alignItems="center">
              {/* <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "5px", width: "60px", height: "20px" }}
                startIcon={<EditOutlined style={{ fontSize: "16px" }} />}
                onClick={() =>
                  history.push({
                    pathname: "/mrf_form",
                    state: { rowId: row?.id },
                  })
                }
              >
                Edit
              </Button> */}
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: "5px", width: "60px", height: "20px" }}
              >
                Assign
              </Button>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={12}>
            <Collapse in={open}>
              <Grid
                style={{
                  border: "1px solid black",
                  padding: "16px",
                }}
                container
                // justifyContent="space-between"
              >
                <Grid container item md={3} style={{ fontSize: "10px" }}>
                  <Grid item xs={5}>
                    <Typography align="left">
                      <strong>Vacancy Id</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>No of Vacancy</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Priority</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Job Type</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Status</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>
                      <strong>:</strong>
                    </Typography>
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{row?.id}</Typography>
                    <Typography>{row?.no_of_vacancies}</Typography>
                    <Typography>{row?.priority}</Typography>
                    <Typography>{row?.job_type}</Typography>
                    <Typography>{row?.status}</Typography>
                  </Grid>
                </Grid>
                <Grid container item md={5}>
                  <Grid item xs={5}>
                    <Typography align="left">
                      <strong>Base Location</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Minimum Qualification</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Gender Preference</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Department Name</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Raised By Name</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>
                      <strong>:</strong>
                    </Typography>
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{row?.location}</Typography>
                    <Typography>{row?.minimum_qualification}</Typography>
                    <Typography>{row?.gender_preference}</Typography>

                    <Typography>{row?.department}</Typography>
                    <Typography>{row?.employee}</Typography>
                  </Grid>
                </Grid>

                <Grid container item md={4}>
                  <Grid item xs={5}>
                    <Typography align="left">
                      <strong>Branch</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Salary Range</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Skill</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Zone</strong>
                    </Typography>
                    <Typography align="left">
                      <strong>Position</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>
                      <strong>:</strong>
                    </Typography>
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                    <Typography>
                      <strong>:</strong>
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{row?.branch}</Typography>
                    <Typography>{row?.salary}</Typography>
                    <Typography>{row?.skill}</Typography>
                    <Typography>{row?.zone}</Typography>
                    <Typography>{row?.position}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <React.Fragment>
      {loading && <Loader open />}
      <>
        <Grid container>
          <Grid item md={3} xs={12}>
            <Typography className="headings" variant="h4">
              <strong>MRF List</strong>
            </Typography>
          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={3}></Grid>
          <Grid item md={3} xs={12}>
            <TextField
              id="search-bar"
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              autoComplete="on"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Paper className={classes.paper}>
          <Grid
            item
            md={12}
            container
            spacing={1}
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Grid item md={3} xs={12}>
              <TextField
                name="startDate"
                label="Start Date"
                margin="dense"
                variant="outlined"
                fullWidth
                required
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate || ""}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                name="endDate"
                label="End Date"
                margin="dense"
                variant="outlined"
                fullWidth
                required
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate || ""}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                name="positionType"
                label="Position Type"
                margin="dense"
                variant="outlined"
                fullWidth
                required
                select
                value={positionType ?? ""}
                onChange={(e) => setPositionType(e.target.value)}
              >
                {positionTypeList &&
                  positionTypeList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                name="mrfStatus"
                label="MRF Status"
                margin="dense"
                variant="outlined"
                fullWidth
                required
                select
                value={mrfStatus ?? ""}
                onChange={(e) => setMrfStatus(e.target.value)}
              >
                {mrfStatusList &&
                  mrfStatusList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            container
            spacing={1}
            style={{
              justifyContent: "end",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              md={12}
              container
              spacing={1}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Grid item md={1} xs={6}>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth
                  onClick={() => handleClear()}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item md={1} xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => fetchMrfList()}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => handleExportReport()}
                  // disabled={!startDate || !endDate ? true : false}
                >
                  Export Report
                </Button>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={() => history.push("/mrf_form")}
                  startIcon={<AddCircleOutlineOutlined />}
                >
                  Add MRF
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            md={12}
            xs={12}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Grid item md={2} style={{ color: "grey" }}>
              Select date range to export
            </Grid>
          </Grid> */}
        </Paper>
        <Divider className={classes.divider} />
        <Paper>
          <Grid style={{ marginTop: "10px" }}>
            {mrfList && mrfList?.length === 0 && (
              <h1
                style={{ textAlign: "center", padding: "10%", color: "blue" }}
              >
                No Records Found For Selected Filters
              </h1>
            )}
            {mrfList && mrfList?.length !== 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: "1px solid black" }} />
                    <TableCell
                      align="left"
                      style={{ border: "1px solid black" }}
                    >
                      <strong>Raised By</strong>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ border: "1px solid black" }}
                    >
                      <strong>Position</strong>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ border: "1px solid black" }}
                    >
                      <strong>Department</strong>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ border: "1px solid black" }}
                    >
                      <strong>Experience</strong>
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      <strong>Status</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      <strong>Action</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mrfList.map((row) => (
                    <Row key={row?.id} row={row} />
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelDisplayedRows={() =>
                        `Page ${currentPage + 1} of ${totalPages}`
                      }
                      rowsPerPageOptions={false}
                      page={currentPage}
                      onChangePage={(e, newPage) => setCurrentPage(newPage)}
                      nextIconButtonProps={{
                        disabled: currentPage === totalPages - 1,
                      }}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Grid>
        </Paper>
      </>
    </React.Fragment>
  );
};

MrfList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(MrfList);
